import Head from "next/head";
import Link from "next/link";

import { Col, Container, Row } from "react-bootstrap";

import SEO from "../components/SEO";

const ErrorPage = () => {
  return (
    <>
      <Head>{SEO("Nie znalezniono strony")}</Head>
      <Container>
        <Row>
          <Col md={12}>
            <h2>Nie znaleziono strony</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            Strona, której szukasz nie została odnaleziona. Przejdź na{" "}
            <Link href="/" passHref>
              <a>stronę główną</a>
            </Link>
            .
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ErrorPage;
